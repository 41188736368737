import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

import { ArticleAuthors } from '@/components/molecules';
import { ArticleCardFragment } from '@/graphql/generated';
import { dropHTMLTags } from '@/utils/drop-html-tags';
import { getImageAlt, getImageSrcWithBlurhash } from '@/utils/images';
import { BlogPath, getDynamicPath } from '@/utils/models';

export interface ArticleCardProps {
  article: ArticleCardFragment;
  noPhoto?: boolean;
}

export const ArticleCardMd = ({ article, noPhoto }: ArticleCardProps) => {
  const { title, lead, authors, creationDate, tags, minutesOfRead, coverImage, slug } = article;

  const image = coverImage.data?.attributes;
  const authorsData = authors?.data || [];

  return (
    <Link href={getDynamicPath(BlogPath.ARTICLE, slug)}>
      {!noPhoto && (
        <Box
          h={{
            base: '240px',
            '2xl': '360px',
          }}
          w="full"
          borderRadius="24px"
          overflow="hidden"
          mb="8"
        >
          <Image
            {...getImageSrcWithBlurhash(image)}
            alt={getImageAlt(image, title)}
            width={image?.width ?? undefined}
            height={image?.height ?? undefined}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      )}
      <Flex mb={noPhoto ? 4 : 2} gap="2" wrap="wrap">
        {tags?.data.map((tag) => (
          <Tag variant="article" key={tag.id}>
            {tag.attributes?.name}
          </Tag>
        ))}
      </Flex>

      <Text
        variant={{
          base: 'component-heading-large/lg',
          sm: 'component-heading-large/xl',
        }}
        color="purple.500"
        mb="6"
        noOfLines={3}
      >
        {dropHTMLTags(title)}
      </Text>
      <Text variant="text-md/lineHeight-6/font-normal" mb="6" color="purple.400" noOfLines={5}>
        {dropHTMLTags(lead)}
      </Text>

      <ArticleAuthors authors={authorsData} createdAt={creationDate} minutesOfRead={minutesOfRead || 0} />
    </Link>
  );
};
