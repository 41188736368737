import { useToast } from '@chakra-ui/react';

import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { useAddSubscriberToNewsletterMutation } from '@/graphql/generated';

import { NewsletterBackground } from './NewsletterBackground';
import { NewsletterForm, NewsletterFormProps } from './NewsletterForm';
import { NewsletterSubscribeSuccess } from './NewsletterSubscribeSuccess';

export const Newsletter = () => {
  const { mutateAsync: subscribe, isSuccess } = useAddSubscriberToNewsletterMutation();

  const toast = useToast();

  const onNewsletterRequestSubmit: NewsletterFormProps['onSubmit'] = async (values) => {
    try {
      await subscribe({
        email: values.email,
        captchaToken: values.captchaToken,
      });
    } catch (e) {
      toast({
        status: 'error',
        title: (e as unknown as Error).toString() || 'Unexpected error occurred',
      });
    }
  };

  const onNewsletterRequestError = () => {
    toast({
      status: 'error',
      title: "Couldn't submit form 😔",
    });
  };

  return (
    <SectionWrapper zIndex={0} overflow="hidden" backgroundColor="purple.500">
      <SectionContentWrapper
        paddingY={10}
        display="flex"
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        paddingX={6}
        maxWidth="container.lg"
        justifyContent="space-between"
        position="relative"
        gap={{
          base: 8,
          sm: 12,
        }}
        alignItems="center"
      >
        <NewsletterBackground />
        {isSuccess ? (
          <NewsletterSubscribeSuccess />
        ) : (
          <NewsletterForm onSubmit={onNewsletterRequestSubmit} onError={onNewsletterRequestError} />
        )}
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
