import { Flex, Icon } from '@chakra-ui/react';
import StarIcon from 'images/icons/star.svg';

interface StarsRatingProps {
  rating: number;
}

export const StarsRating = ({ rating }: StarsRatingProps) => {
  const numberOfStars = 5;
  const fullyFilledStars = Math.floor(rating);
  const partiallyFilledStars = rating - fullyFilledStars;
  const emptyStars = Math.floor(numberOfStars - fullyFilledStars - partiallyFilledStars);

  return (
    <Flex gap={1}>
      {Array.from(Array(fullyFilledStars).keys()).map((_, index) => (
        <Icon key={index} as={StarIcon} w="16px" h="16px" sx={{ '.star_svg__filled': { x: '0' } }} />
      ))}
      {partiallyFilledStars > 0 && (
        <Icon
          as={StarIcon}
          w="16px"
          h="16px"
          sx={{
            '.star_svg__filled': {
              x: `${-(100 - partiallyFilledStars * 100)}%`,
            },
          }}
        />
      )}
      {emptyStars > 0 &&
        Array.from(Array(emptyStars).keys()).map((_, index) => (
          <Icon key={index} as={StarIcon} w="16px" h="16px" sx={{ '.star_svg__filled': { x: '100%' } }} />
        ))}
    </Flex>
  );
};
