import { defineStyle, keyframes } from '@chakra-ui/react';

export const caseStudyStyles = defineStyle({
  color: 'white',
  maxWidth: '100%',
  cursor: 'pointer',
  position: 'relative',
  '.eyeHoverIcon': {
    transition: 'transform 1s, opacity .5s',
    transform: 'translateY(0)',
    opacity: 0,
  },
  ':hover .eyeHoverIcon': {
    opacity: 1,
    transform: 'translateY(-80%)',
  },
  '.caseStudyImage': {
    transition: 'transform .5s',
  },
  ':hover .caseStudyImage': {
    md: {
      transform: 'scale(1.1)',
    },
  },
});

export const blinkingEyeAnimation = keyframes`
  0%, 90%, 100% {
    cursor: url("/assets/images/doodles/eye/1.svg") 62 62, auto;
  }
  92%, 97% {
    cursor: url("/assets/images/doodles/eye/2.svg") 62 62, auto;
  }
  95%{
    cursor: url("/assets/images/doodles/eye/3.svg") 62 62, auto;
  }
`;
