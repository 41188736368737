import { Box, Flex, FlexProps, Text, defineStyle } from '@chakra-ui/react';

interface TagGroupProps extends FlexProps {
  tags: string[];
  variant?: 'dark' | 'light' | 'category';
}

export const TagGroup = ({ tags, variant = 'dark', ...flexProps }: TagGroupProps) => {
  const isDarkVariant = variant === 'dark';
  const isCategoryVariant = variant === 'category';

  return (
    <Flex mt={6} gap="2" wrap="wrap" {...flexProps}>
      {tags.map((tagName) => (
        <Box
          key={'filter_tag_button_' + tagName}
          style={TagStyle}
          border="1px solid"
          borderColor={isDarkVariant ? 'violet.400' : isCategoryVariant ? 'beige.200' : 'violet.200'}
          p={2}
          backgroundColor={isCategoryVariant ? 'beige.200' : 'transparent'}
          borderRadius="20px"
          h={6}
        >
          <Text
            color={isDarkVariant ? 'purple.200' : 'purple.400'}
            variant="text-xs/lineHeight-4/font-medium/letterSpacing-wider/0.75rem"
            textTransform="capitalize"
          >
            {tagName}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

const TagStyle = defineStyle({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 'fit-content',
});
