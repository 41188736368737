import { FormEvent, useRef, useState } from 'react';

import { Checkbox, Flex, Grid, Input, Text } from '@chakra-ui/react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { zodResolver } from '@hookform/resolvers/zod';
import ExclamationMark from 'images/icons/exclamation-mark.svg';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/atoms';
import { ButtonVariant } from '@/graphql/generated';
import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models/page-paths';

const newsletterFormSchema = z.object({
  email: z.string().min(1, { message: 'Please complete this required field.' }).email({
    message: 'Email must be formatted correctly.',
  }),
  agreement: z.boolean().refine((value) => value === true),
});

type NewsletterFormData = z.infer<typeof newsletterFormSchema>;

export interface NewsletterFormProps {
  onSubmit: (values: NewsletterFormData & { captchaToken: string }) => void | Promise<void>;
  onError: (e?: Error) => void;
}

export const NewsletterForm = ({ onSubmit: onSubmitProp, onError }: NewsletterFormProps) => {
  const { t } = useTranslation([LOCALES.COMMON]);
  const captchaRef = useRef<HCaptcha>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const form = useForm<NewsletterFormData>({
    resolver: zodResolver(newsletterFormSchema),
    defaultValues: {
      email: '',
      agreement: false,
    },
  });
  const { register, handleSubmit, formState, reset } = form;
  const { errors, isSubmitting } = formState;

  const onSubmit = async (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    let token = captchaToken;
    if (!token) {
      const captchaResult = await captchaRef.current?.execute({ async: true });
      if (captchaResult?.response) {
        token = captchaResult?.response;
      }
    }

    handleSubmit(async (values) => {
      try {
        if (!token) {
          throw new Error('Captcha token is not available');
        }
        await onSubmitProp({
          ...values,
          captchaToken: token,
        });
        reset();
      } catch (e) {
        onError(e as unknown as Error);
      } finally {
        resetCaptcha();
      }
    })();
  };

  const resetCaptcha = (): void => {
    setCaptchaToken(null);
    captchaRef.current?.resetCaptcha();
  };

  const onTokenVerify = async (token: string) => setCaptchaToken(token);

  return (
    <>
      <Text
        color="white"
        variant={{
          base: 'component-heading-large/lg',
          sm: 'component-heading-large/xl',
        }}
        as="span"
        sx={{
          textWrap: 'balance',
        }}
        textAlign={{
          base: 'center',
          sm: 'left',
        }}
      >
        {t('newsletter.info')}
      </Text>
      <Flex
        as="form"
        flexDir={{
          base: 'column',
          sm: 'row',
        }}
        gap="4"
        maxW={{ sm: '542px' }}
        onSubmit={onSubmit}
        noValidate
      >
        <Grid flex="1">
          <Input
            {...register('email')}
            type="email"
            bg="white"
            placeholder={t('newsletter.email')}
            required
            size={{
              base: 'md',
              sm: 'lg',
            }}
            isInvalid={!!errors.email}
            isReadOnly={isSubmitting}
            mb={2.5}
          />
          {errors.email && (
            <Flex alignItems="center">
              <ExclamationMark />
              <Text ml={2} color="red.500" variant="text-sm/lineHeight-5/font-semibold">
                {errors.email.message}
              </Text>
            </Flex>
          )}
          <Flex align="flex-start" gap="3" mt={4}>
            <Checkbox
              {...register('agreement')}
              bg="white"
              borderRadius="base"
              required
              isInvalid={!!errors.agreement}
              isReadOnly={isSubmitting}
              size="lg"
            />
            <Text
              variant="text-xs/lineHeight-4/font-normal/0.75rem"
              color={errors.agreement ? 'red.500' : 'white'}
              as="span"
            >
              {t('newsletter.marketingLine1')}{' '}
              <Link
                href={GeneralPath.PRIVACY_POLICY}
                style={{
                  fontWeight: 'var(--chakra-fontWeights-semibold)',
                  textDecoration: 'underline',
                }}
              >
                {t('newsletter.marketingLine2')}
              </Link>
            </Text>
          </Flex>
        </Grid>
        <HCaptcha
          onVerify={onTokenVerify}
          ref={captchaRef}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY}
        />
        <Button
          size={{
            base: 'md',
            sm: 'lg',
          }}
          isLoading={isSubmitting}
          type="submit"
          variant={ButtonVariant.Dark}
        >
          {t('newsletter.subscribe')}
        </Button>
      </Flex>
    </>
  );
};
