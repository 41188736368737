import { Box, Grid, GridItem } from '@chakra-ui/react';

import { CaseStudyFragment } from '@/graphql/generated';

import { CaseStudy as CaseStudyComponent } from '../../home/CaseStudies/CaseStudy';

interface CaseStudiesListProps {
  caseStudies: CaseStudyFragment[];
  isHomepageSection?: boolean;
  extraItem?: JSX.Element;
  showTags?: boolean;
}

export const CaseStudiesList = ({ caseStudies, isHomepageSection, showTags }: CaseStudiesListProps) => {
  const extraBottomMargin = !isHomepageSection && caseStudies.length % 2 === 0;

  return (
    <>
      {isHomepageSection && (
        <Box
          background="linear-gradient(180deg, rgba(251, 248, 240, 0.00) 0%, #FBF8F0 100%)"
          w="full"
          h={{
            base: '400px',
            md: '660px',
          }}
          position="absolute"
          bottom={0}
          left={0}
          zIndex={100}
          pointerEvents="none"
        />
      )}
      <Grid
        gridTemplateColumns={{
          base: '',
          md: isHomepageSection ? 'repeat(2, 1fr)' : 'repeat(2, 50%)',
        }}
        display={{
          base: 'flex',
          md: 'grid',
        }}
        flexDir="column"
        rowGap={{ base: 16, md: 20 }}
        columnGap={7}
        id="caseStudyGrid"
        mb={extraBottomMargin ? 32 : 10}
      >
        {caseStudies.map((caseStudy, index) => {
          if (!caseStudy) return null;
          const { description, title, image, slug, caseStudyTags } = caseStudy.attributes!;
          const tags = (caseStudyTags?.data.map((t) => t.attributes?.name).filter((t) => !!t) as string[]) || [];

          const shouldOffset = isHomepageSection ? false : index % 2 === 0;
          return (
            <GridItem
              key={'case_study_' + slug}
              transform={
                shouldOffset
                  ? {
                      base: 'none',
                      md: 'translateY(150px)',
                    }
                  : 'none'
              }
              _last={
                shouldOffset
                  ? {
                      mb: { base: 0, md: '150px' },
                    }
                  : {}
              }
            >
              <CaseStudyComponent
                heading={title}
                description={description}
                slug={slug}
                image={image}
                tags={tags}
                showTags={showTags}
              />
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
};
