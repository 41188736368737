import { useEffect, useRef } from 'react';

import { Box, BoxProps, keyframes } from '@chakra-ui/react';
import Script from 'next/script';

import { WindowWithInterfaces } from '@/typings/window';
import { CLUTCH_COMPANY_ID, CLUTCH_SCRIPT_URL, CLUTCH_WIDGET_URL } from '@/utils/constants';

/**
 *  The 'scale' prop takes an object with key-value pairs: <breakpoint, scaleValue>.
 *  The object follows Chakra UI's object syntax:
 *  https://chakra-ui.com/docs/styled-system/responsive-styles#the-object-syntax
 *
 * The 'starColor' prop takes CSS 'color' property:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/color
 */

const WIDGET_HEIGHT = '45px';
const DEFAULT_SCALE: ClutchWidgetProps['scale'] = { base: 1 };
const DEFAULT_STAR_COLOR = '#7D54D6';

type ClutchWidgetProps = {
  starColor?: string;
} & BoxProps;

export const ClutchWidget = ({ starColor = DEFAULT_STAR_COLOR, ...style }: ClutchWidgetProps) => {
  const clutchContainer = useRef(null);

  useEffect(() => {
    if (window) {
      const _window = window as WindowWithInterfaces;
      _window.CLUTCHCO && _window.CLUTCHCO.Init();
    }
  }, []);

  return (
    <>
      <Script
        src={CLUTCH_SCRIPT_URL}
        strategy="afterInteractive"
        onLoad={() => window && (window as WindowWithInterfaces).CLUTCHCO.Init()}
      />
      <Box transformOrigin="top left" scale={DEFAULT_SCALE} sx={style} height={WIDGET_HEIGHT} ref={clutchContainer}>
        <Box
          className="clutch-widget"
          data-widget-type="2"
          data-scale="100"
          data-expandifr="true"
          data-nofollow="true"
          data-url={CLUTCH_WIDGET_URL}
          data-clutchcompany-id={CLUTCH_COMPANY_ID}
          data-height={parseInt(WIDGET_HEIGHT)}
          data-primary-color={starColor}
          sx={{
            iframe: {
              opacity: 0,
              animation: `${appearAnimation} 1s 0.5s forwards`,
            },
          }}
        />
      </Box>
    </>
  );
};

export const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
