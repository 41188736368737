import { Box, Flex, Text } from '@chakra-ui/react';
import YellowCheckWithStars from 'images/doodles/yellow-check-with-stars.png';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { LOCALES } from '@/utils/constants';

export const NewsletterSubscribeSuccess = () => {
  const { t } = useTranslation([LOCALES.COMMON]);
  return (
    <Flex
      mx="auto"
      gap="8"
      align="center"
      maxW="694px"
      flexDirection={{
        base: 'column',
        sm: 'row',
      }}
      my="-4"
    >
      <Box
        sx={{
          '& .check-doodle': {
            w: '156px',
            h: '124px',
          },
        }}
      >
        <Image src={YellowCheckWithStars} alt="" className="check-doodle" />
      </Box>
      <Text
        as="span"
        flex="1"
        textAlign={{
          base: 'center',
          sm: 'start',
        }}
        color="white"
        variant="component-heading-large/xl"
      >
        {t('newsletter.success')}
      </Text>
    </Flex>
  );
};
