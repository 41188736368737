import { Box } from '@chakra-ui/react';
import Twirl from 'images/backgrounds/twirl.png';
import Image from 'next/image';

export const NewsletterBackground = () => {
  return (
    <Box
      as={Image}
      src={Twirl}
      alt=""
      position="absolute"
      zIndex={-1}
      top={{
        base: 0,
        sm: '-20%',
      }}
      left={{
        base: '-14%',
        sm: 0,
      }}
      transform="rotate(-30deg)"
      width={{
        base: '190%',
        sm: '90%',
        lg: '100%',
      }}
      maxW="unset"
    />
  );
};
