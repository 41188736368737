import { Box, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { TextHighlightSvgStatic } from '@/components/functional';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { LOCALES } from '@/utils/constants';

import { LetsBuildSomethingBackground } from './LetsBuildSometingBackground';

type LetsBuildSomethingSectionProps = {
  overflow?: boolean;
};

export const LetsBuildSomethingSection = ({ overflow = true }: LetsBuildSomethingSectionProps) => {
  const { t } = useTranslation([LOCALES.COMMON]);
  return (
    <SectionWrapper
      backgroundColor="purple.500"
      zIndex={0}
      id="caseStudiesSection"
      h={{ base: '570px', sm: '440px', md: '400px' }}
      overflow={overflow ? 'visible' : 'hidden'}
    >
      <SectionContentWrapper
        py={20}
        px={{ base: 5, md: 20 }}
        position="relative"
        h="100%"
        display="flex"
        justifyContent={{ base: 'center', sm: 'start' }}
        alignItems={{ base: 'start', sm: 'center' }}
      >
        <LetsBuildSomethingBackground />

        <Box
          maxW={{ base: 'min(60%, 200px)', xs: '200px', md: '300px' }}
          ml={{
            base: '0',
            sm: '12%',
            md: '8%',
            xl: '12%',
          }}
        >
          <TextHighlightSvgStatic backgroundImage="url('/assets/images/highlights/text-highlight.svg')" offsetX="-20px">
            <Heading
              variant={{
                base: 'component-heading-large/xl',
                md: 'component-heading-large/3xl',
              }}
              color="white"
              textAlign={{ base: 'center', sm: 'start' }}
            >
              <Text color="purple.500" className="text-highlight">
                {t('buildSomething.highlighted')}
              </Text>{' '}
              {t('buildSomething.text')}
            </Heading>
          </TextHighlightSvgStatic>
        </Box>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};
