import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import Image from 'next/image';
import NextLink from 'next/link';

import { TagGroup } from '@/components/molecules';
import { CustomDoodle } from '@/components/sections/common';
import { CaseStudyBackground } from '@/components/sections/home/CaseStudies/CaseStudyBackground/CaseStudyBackground';
import { ImageFragment } from '@/graphql/generated';
import { getImageAlt } from '@/utils/images';
import { CaseStudiesPath, getDynamicPath } from '@/utils/models';

import { blinkingEyeAnimation, caseStudyStyles } from '../styles';

interface CaseStudyProps {
  heading: string;
  description: string;
  image: ImageFragment;
  slug: string;
  doodles?: CustomDoodle[];
  highlightRandomEffect?: string;
  tags?: string[];
  showTags?: boolean;
}

export const CaseStudy = ({ heading, description, image, slug, tags, showTags }: CaseStudyProps) => {
  return (
    <Flex role="group" className="caseStudy" id="landingCaseStudy" sx={caseStudyStyles} flexDirection="column">
      <Box
        width="100%"
        height={{ base: '380px', md: '434px' }}
        position="relative"
        overflow="hidden"
        borderRadius="16px"
      >
        <CaseStudyBackground />
        <Link
          as={NextLink}
          href={getDynamicPath(CaseStudiesPath.CASE_STUDY, slug)}
          w="100%"
          h="100%"
          _hover={{
            md: {
              animation: `${blinkingEyeAnimation} 3s infinite`,
            },
          }}
        >
          <Image
            fill
            src={image.data?.attributes?.url ?? ''}
            alt={getImageAlt(image?.data?.attributes, heading)}
            style={{ objectFit: 'contain' }}
            className="caseStudyImage"
            sizes="(max-width: 911px) 100vw, 870px"
          />
        </Link>
      </Box>
      <Box mt={{ base: 8 }}>
        <Link as={NextLink} href={getDynamicPath(CaseStudiesPath.CASE_STUDY, slug)} w="full" zIndex="0">
          <Heading
            as="h3"
            variant={{
              base: 'component-heading-large/lg',
              sm: 'component-heading-large/2xl',
            }}
            position="relative"
            color="purple.500"
            _groupHover={{
              textDecor: 'underline',
            }}
          >
            {heading}
          </Heading>
        </Link>
        <Link as={NextLink} href={getDynamicPath(CaseStudiesPath.CASE_STUDY, slug)} _hover={{ textDecoration: 'none' }}>
          <Text
            variant={{
              base: 'text-md/lineHeight-6/font-normal',
              sm: 'text-xl/lineHeight-7/font-normal',
            }}
            pt={{ base: 5, md: 8 }}
            color="purple.500"
          >
            {description}
          </Text>
        </Link>
        {showTags && !!tags?.length && <TagGroup tags={tags} variant="light" />}
      </Box>
    </Flex>
  );
};
