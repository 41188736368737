import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { UsersAvatars } from '@/components/atoms';
import { UserFragment } from '@/graphql/generated';
import { useDateFormatter } from '@/hooks';
import { LOCALES } from '@/utils/constants';

export interface ArticleAuthorsProps {
  authors: UserFragment[];
  createdAt: DateLike;
  minutesOfRead?: number;
}

export const ArticleAuthors = ({ authors, createdAt, minutesOfRead: minutesRead }: ArticleAuthorsProps) => {
  const formatDate = useDateFormatter();
  const { t } = useTranslation([LOCALES.COMMON]);

  return (
    <Flex align="center" gap="2" pr={6}>
      <UsersAvatars users={authors} />
      <Flex flexDir="column" gap="1" maxW="calc(100% - 70px)">
        <Text variant="text-sm/lineHeight-5/font-medium" color="purple.400">
          {authors.map((author) => author.attributes?.username).join(' ・ ')}
        </Text>
        {minutesRead && createdAt ? (
          <Text variant="text-sm/lineHeight-5/font-book" color="purple.400">
            <Box as="span" mr={1.5}>
              {formatDate(createdAt)}
            </Box>
            ・
            <Box as="span" ml={1.5}>
              {minutesRead} {t('minRead')}
            </Box>
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};
