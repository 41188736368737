import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import { StaticBackground } from '@/components/atoms/StaticBackground';
import { shuffleArray } from '@/utils/helper-functions/arrays';

const colors = ['violet.200', 'pink.200', 'yellow.100', 'beige.200', 'green.100'];

export const CaseStudyBackground = () => {
  const shuffledColors = useMemo(() => shuffleArray(colors), []);
  return (
    <Box position="relative" width="100%" height="100%">
      <StaticBackground w="30%" h="50%" left="0" top="15%" backgroundColor={shuffledColors[0]} opacity={0.4} />
      <StaticBackground w="20%" h="20%" left="40%" top="55%" backgroundColor={shuffledColors[1]} opacity={0.4} />
      <StaticBackground w="60%" h="60%" left="30%" top="-40%" backgroundColor={shuffledColors[2]} opacity={0.4} />
      <StaticBackground w="70%" h="70%" left="70%" top="55%" backgroundColor={shuffledColors[3]} opacity={0.4} />
      <StaticBackground w="15%" h="15%" left="40%" top="25%" backgroundColor={shuffledColors[4]} opacity={0.4} />
    </Box>
  );
};
