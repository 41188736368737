export const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const extendArrayByCopying = <T>(arr: T[], max: number): T[] => {
  const extendedArray = [];

  for (let i = 0; i < max; i++) {
    const index = i % arr.length;
    extendedArray.push(arr[index]);
  }

  return extendedArray;
};
