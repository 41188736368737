import { Box, Link, VisuallyHidden } from '@chakra-ui/react';
import Twirl from 'images/backgrounds/twirl.png';
import Image from 'next/image';
import NextLink from 'next/link';

import { GeneralPath } from '@/utils/models';

import { RotatingLetsBuildTogether } from './RotatingLetsBuildTogether';

export const LetsBuildSomethingBackground = () => {
  return (
    <>
      <Box position="absolute" left={0} bottom="0" width="100%" height="150%" overflow="hidden">
        <Box
          width="1480px"
          minW="1480px"
          height="auto"
          as={Image}
          alt=""
          src={Twirl}
          zIndex={-1}
          position="absolute"
          left={{
            base: '-120%',
            xs: '-60%',
            sm: 'unset',
          }}
          right={{
            base: 'unset',
            sm: '-20%',
            md: '-7%',
            lg: '0',
            xl: '5%',
            '3xl': '10%',
          }}
          top={{ base: '-5%', xs: '-8%', sm: '0px' }}
          transform={{
            base: 'scale(0.5) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
            xs: 'scale(0.7) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
            sm: 'scale(0.9) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
            md: 'scale(1) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
            '2xl': 'scale(1.1) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
            '3xl': 'scale(1.1) matrix(0.83, -0.56, 0.56, 0.83, 0, 0)',
          }}
        />
      </Box>
      <Box
        position="absolute"
        w="192px"
        h="192px"
        right={{
          base: '50%',
          sm: '12em',
          md: '18em',
          lg: '22em',
          xl: '26em',
          '2xl': '30em',
          '3xl': '36em',
        }}
        transform="translateX(50%)"
        bottom={{ base: '4em', sm: '26%', lg: '28%' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          '& svg': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Link as={NextLink} href={GeneralPath.CONTACT} w="100%" h="100%" cursor="pointer">
          <VisuallyHidden>Let&apos;s talk</VisuallyHidden>
          <RotatingLetsBuildTogether />
        </Link>
      </Box>
    </>
  );
};
