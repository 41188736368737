import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import LetsTalkCap from 'images/doodles/lets-talk-cap.svg';
import LetsTalkFace from 'images/doodles/lets-talk-face.png';
import Image from 'next/image';

export const RotatingLetsBuildTogether = () => (
  <Animated>
    <Flex
      w="132px"
      h="132px"
      borderRadius="50%"
      backgroundColor="violet.500"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      justifyContent="center"
      alignItems="center"
    >
      <Box as={Image} alt="let's talk" src={LetsTalkFace} w="116px" h="104px" />
    </Flex>

    <Box position="absolute" w="100%" h="100%" left={0} top={0} className="rotatingCaption">
      <LetsTalkCap alt="let's talk" />
    </Box>
  </Animated>
);

const Animated = styled.div`
  @keyframes rotation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  .rotatingCaption {
    animation: rotation 20s linear infinite;
  }
`;
